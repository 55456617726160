<template>
  <v-container>
    <v-row class="justify-center" >
      <v-col cols="12" md="6">
        <v-card class="elevation-12 text-center rounded-xl" color="#F0F1FF">
          <v-card-title class="mt-2">
            <v-img src="../../assets/ulink.png" contain max-height="60"></v-img>
            
          </v-card-title>
          <v-card-subtitle class="mt-2">
            <h3>Completa tu registro:</h3>
          </v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12">
                      <v-text-field 
                        v-model="newUser.email"
                        label="Email" 
                        filled
                        outlined 
                        dense
                        prepend-icon="mdi-at"
                        :rules="emailRules"
                      >
                      </v-text-field>
                      <v-text-field 
                        v-model="newUser.name"
                        label="Nombre" 
                        filled
                        outlined 
                        dense
                        prepend-icon="mdi-account"
                        :rules="[v => !!v || 'Nombres es requerido']"
                      >
                      </v-text-field>
                      <!-- <v-text-field 
                        v-model="newUser.company"
                        label="Empresa" 
                        filled
                        outlined 
                        dense
                        prepend-icon="mdi-domain"
                      >
                      </v-text-field> -->
                      <div>
                        <!--<div class="mb-6">Active picker: <code>{{ activePicker || 'null' }}</code></div>-->
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >

                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              filled
                              outlined 
                              dense
                              v-model="newUser.birthday"
                              label="Fecha de nacimiento"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="[v => !!v || 'Fecha es requerdio']"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            no-title
                            v-model="newUser.birthday"
                            :active-picker.sync="activePicker"
                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="save"
                            locale="es-mx"
                          ></v-date-picker>
                        </v-menu>
                      </div>                    

                  </v-col>
                     <v-btn @click="validate" block color="primary" elevation="8" rounded :disabled="!valid">Ingresar</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          
                 
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RegisterEmail',
  data() {
    return {
      valid: true,
      emailRules: [
        value => !!value || 'Requerido.',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Formato de email inválido'
        },
      ],
      
      activePicker: null,
      menu: false,
    }
  },

  computed: {
    ...mapState(['newUser'])
  },

  methods: {
    ...mapActions(['registerUser']),
    
    save (date) {
        this.$refs.menu.save(date)
    },

    validate () {
        if(this.$refs.form.validate()){
          this.registerUser(this.newUser)
        }
    },
  },

  watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },

}
</script>
