<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" md="6">
        
        <v-card class="elevation-12 text-center rounded-xl" color="#F0F1FF">
          <v-card-title class="mt-2">
            <v-img src="../../assets/ulink.png" contain max-height="60"></v-img>
          </v-card-title>
          <v-card-subtitle class="mt-2">
            <h3>Ingresa con tu correo:</h3>
          </v-card-subtitle>
          <v-card-text>
            <!--<v-form @submit.prevent="logInEmail(email)">-->
              <v-form ref="form" v-model="valid" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    
                      <v-text-field 
                        v-model="email"
                        label="Email" 
                        filled
                        outlined 
                        dense
                        :rules="emailRules"
                      >
                      </v-text-field>
                     <v-btn @click="validate" block color="primary" elevation="8" rounded :disabled="!valid">Ingresar</v-btn>
                    
                  </v-col>   
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          
          <!--<v-divider class="mt-2"></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                 <h3>ó ingresa con:</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn @click="facebookLogIn()" color="#3b5998" elevation="8" fab ><v-icon x-large color="white">mdi-facebook</v-icon></v-btn>
                <v-btn @click="googleLogIn()" color="#de5246" elevation="8" fab  class="ml-5"><v-icon x-large color="white">mdi-google</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card-text>-->         
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GeneralLogin',
  data() {
    return {
      valid: true,
      email: '',
      emailRules: [
        value => !!value || 'Requerido.',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Formato de email inválido'
        },
      ],
    }
  },

  methods: {
    ...mapActions(['logInEmail','facebookLogIn','googleLogIn']),

    validate () {
        if(this.$refs.form.validate()){
          this.logInEmail(this.email)
        }
      },

  },


  

}
</script>

