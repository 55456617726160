<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h6 class="text-center" style="color: grey;">Powered by</h6>
        <v-img src="../assets/anukin.png" max-width="50" class="mx-auto"></v-img>
        <h6 class="text-center" style="color: grey;">&reg;</h6>
      </v-col>
    </v-row>
  </v-container> 
</template>

<script>
export default {
  name: 'Powered',

}
</script>