<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        
      </v-col>
    </v-row>
  </v-container> 
</template>

<script>
import router from '../../router/index'
import { db } from "../../firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore"
import { mapActions, mapState } from 'vuex'


export default {
  name: 'Tag',
  data() {
      return {
        clientTag: {
          client: '',
          tag: ''
        }
          
          
      }
  },

  computed: {
    ...mapState(['userkin','tag'])
      
  },

  methods: {
      ...mapActions(['setClientTag']),

      async goToUrl(){
        try {
          this.clientTag.client = this.$route.params.clientId
          this.clientTag.tag = this.$route.params.tag
          //console.log(this.clientTag)
          await this.setClientTag(this.clientTag)
          const url = JSON.parse(localStorage.getItem('urlkin'))
          //console.log(url)
          //console.log(this.userkin)
          //console.log(this.tag)

          const visit = Object.assign({}, this.userkin, this.tag)
          visit.interaction = 'SCAN'
          visit.created = Timestamp.now()
          //console.log(visit.birthday)
          if(visit.birthday.seconds){visit.birthday = new Timestamp(visit.birthday.seconds, 0)}
          else{visit.birthday = null}

          if(visit.tagId && visit.clientId && visit.enabled === true){
            await addDoc(collection(db, "interactions"), visit)
            window.location.replace(url)
            //console.log("Si entró a tag pero no direcciona por pruebas")
          }
          else{
            //console.log("No existe tag y/o cliente...")
            window.location.replace("https://anukin.com")
          }
        } catch (error) {
          console.log(error)
        }
     
      }
    
  },

  created() {
      
  },

  mounted() {
      this.goToUrl()
  },

}
</script>