<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Powered />
        <GeneralLogin v-if="isAuth == false && registerUserFlag == false" />
        <RegisterEmail v-if="registerUserFlag == true" />
        <Tag v-if="isAuth == true" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Powered from '../../components/Powered.vue'
import GeneralLogin from '../../components/general/GeneralLogin.vue'
import RegisterEmail from '../../components/general/RegisterEmail.vue'
import Tag from '../../components/general/Tag.vue'

import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Powered,
    GeneralLogin,
    Tag,
    RegisterEmail
  },

  data() {
    return {
      clientTag: {
        client: '',
        tag: ''
      },
    }
  },

  computed: {
    ...mapState(['userkin','tag','registerUserFlag']),
    isAuth(){
      return this.userkin != null ? true : false
    },
    

  },

  methods: {
    ...mapActions(['setClientTag','detectuserkin']),

    async getClientTag(){
      this.clientTag.client = this.$route.params.clientId
      this.clientTag.tag = this.$route.params.tag
      //console.log(this.clientTag)
      this.setClientTag(this.clientTag)
    },
  },

  async created() {
    await this.getClientTag()
    this.detectuserkin()
  },

}
</script>

<style>

</style>